@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-2xl;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-bold break-words;
    }

    h2, h3 {
        @apply text-xl font-thin;
    }

    p {
        @apply mb-5 font-light;
    }

    p:last-of-type {
        @apply mb-0;
    }

    p a {
        text-decoration: underline;
    }

    p.text-indent {
        text-indent: 4rem;
    }

    hr {
        @apply border-t-2 border-blue mb-2 sm:mb-4 md:mb-6;
    }

    @font-face {
        font-family: "Surt";
        src: url("./fonts/Surt-Bold.eot");
        src: url("./fonts/Surt-Bold.eot?#iefix") format("embedded-opentype"),
            url("./fonts/Surt-Bold.woff2") format("woff2"),
            url("./fonts/Surt-Bold.woff") format("woff"),
            url("./fonts/Surt-Bold.ttf") format("truetype");
        font-weight: bold;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Surt";
        src: url("./fonts/Surt-Medium.eot");
        src: url("./fonts/Surt-Medium.eot?#iefix") format("embedded-opentype"),
            url("./fonts/Surt-Medium.woff2") format("woff2"),
            url("./fonts/Surt-Medium.woff") format("woff"),
            url("./fonts/Surt-Medium.ttf") format("truetype");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Surt";
        src: url("./fonts/Surt-Light.eot");
        src: url("./fonts/Surt-Light.eot?#iefix") format("embedded-opentype"),
            url("./fonts/Surt-Light.woff2") format("woff2"),
            url("./fonts/Surt-Light.woff") format("woff"),
            url("./fonts/Surt-Light.ttf") format("truetype");
        font-weight: 300;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Surt Medium Extended";
        src: url("./fonts/Surt-MediumExtended.eot");
        src: url("./fonts/Surt-MediumExtended.eot?#iefix")
                format("embedded-opentype"),
            url("./fonts/Surt-MediumExtended.woff2") format("woff2"),
            url("./fonts/Surt-MediumExtended.woff") format("woff"),
            url("./fonts/Surt-MediumExtended.ttf") format("truetype");
        font-weight: 500;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Apoc LC";
        src: url("./fonts/ApocLCTrial-Regular.eot");
        src: url("./fonts/ApocLCTrial-Regular.eot?#iefix")
                format("embedded-opentype"),
            url("./fonts/ApocLCTrial-Regular.woff2") format("woff2"),
            url("./fonts/ApocLCTrial-Regular.woff") format("woff"),
            url("./fonts/ApocLCTrial-Regular.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Apoc LC Revelations";
        src: url("./fonts/ApocLCTrial-Revelations.eot");
        src: url("./fonts/ApocLCTrial-Revelations.eot?#iefix")
                format("embedded-opentype"),
            url("./fonts/ApocLCTrial-Revelations.woff2") format("woff2"),
            url("./fonts/ApocLCTrial-Revelations.woff") format("woff"),
            url("./fonts/ApocLCTrial-Revelations.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Apoc LC Revelations Italic";
        src: url("./fonts/ApocLCTrial-RevelationsItalic.eot");
        src: url("./fonts/ApocLCTrial-RevelationsItalic.eot?#iefix")
                format("embedded-opentype"),
            url("./fonts/ApocLCTrial-RevelationsItalic.woff2") format("woff2"),
            url("./fonts/ApocLCTrial-RevelationsItalic.woff") format("woff"),
            url("./fonts/ApocLCTrial-RevelationsItalic.ttf") format("truetype");
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    @font-face {
        font-family: "Monument Extended";
        src: url("./fonts/MonumentExtended-Black.eot");
        src: url("./fonts/MonumentExtended-Black.eot?#iefix")
                format("embedded-opentype"),
            url("./fonts/MonumentExtended-Black.woff2") format("woff2"),
            url("./fonts/MonumentExtended-Black.woff") format("woff"),
            url("./fonts/MonumentExtended-Black.ttf") format("truetype");
        font-weight: 900;
        font-style: normal;
        font-display: swap;
    }
}
